import React, { useRef, useEffect, useState } from 'react';
import StageParticipants from './StageParticipants';
import { Stage, StageConnectionState, StageEvents, SubscribeType } from 'amazon-ivs-web-broadcast';

export default function IVSVideo({ token, isRenewToken }) {

    const [stageJoined, setStageJoined] = useState(false);
    const [participants, setParticipants] = useState(new Map());
    const [localParticipant, setLocalParticipant] = useState({});

    useEffect(
        () => {
            async function OnLoad() {
                if (token) {
                    //console.log("Start Join Stage", token)
                    isRenewToken = joinStage(token);
                }
            }
            OnLoad();
        }, []
    )

    const stageRef = useRef(undefined);

    const handleParticipantJoin = (participantInfo) => {

        //console.log("Participant Info", participantInfo)
        if (isLocalParticipant(participantInfo)) {
            setLocalParticipant(participantInfo);
        } else {
            const participant = createParticipant(participantInfo);
            // NOTE: we must make a new map so react picks up the state change
            //console.log("Set Participant", participantInfo)
            setParticipants(new Map(participants.set(participant.id, participant)));
        }
    };

    const handleParticipantLeave = (participantInfo) => {
        if (isLocalParticipant(participantInfo)) {
            setLocalParticipant({});
        } else {
            if (participants.delete(participantInfo.id)) {
                setParticipants(new Map(participants));
            }
        }
    };

    const handleConnectionStateChange = (state) => {
        if (state === StageConnectionState.CONNECTED) {
            //console.log("Stage Joined")
            setStageJoined(true);
        } else if (state === StageConnectionState.DISCONNECTED) {
            setStageJoined(false);
        }
    };

    const handleMediaAdded = (participantInfo, streams) => {
        if (!isLocalParticipant(participantInfo)) {
            const { id } = participantInfo;
            let participant = participants.get(id);
            participant = { ...participant, streams: [...streams, ...participant.streams] };
            setParticipants(new Map(participants.set(id, participant)));
        }
    };

    const handleMediaRemoved = (participantInfo, streams) => {
        if (!isLocalParticipant(participantInfo)) {
            const { id } = participantInfo;
            let participant = participants.get(id);
            const newStreams = participant.streams.filter(
                (existingStream) => !streams.find((removedStream) => existingStream.id === removedStream.id)
            );
            participant = { ...participant, streams: newStreams };
            setParticipants(new Map(participants.set(id, participant)));
        }
    };

    const handleParticipantMuteChange = (participantInfo, stream) => {
        if (!isLocalParticipant(participantInfo)) {
            const { id } = participantInfo;
            let participant = participants.get(id);
            participant = { ...participant, ...participantInfo };
            setParticipants(new Map(participants.set(id, participant)));
        }
    };

    function leaveStage() {
        if (stageRef.current) {
            stageRef.current.leave();
        }
    }
    async function joinStage(token) {
        if (!token) {
            alert('Please enter a token to join a stage');
            return true;
        }
        try {
            const strategy = {
                stageStreamsToPublish() {
                    return [];
                },
                shouldPublishParticipant() {
                    return false; // we donot publish any stream
                },
                shouldSubscribeToParticipant(participant) {
                    //console.log('shouldSubscribeToParticipant', participant);
                    return SubscribeType.AUDIO_VIDEO;
                }
            };


            const stage = new Stage(token, strategy);
            stage.on(StageEvents.STAGE_CONNECTION_STATE_CHANGED, handleConnectionStateChange);
            stage.on(StageEvents.STAGE_PARTICIPANT_JOINED, handleParticipantJoin);
            stage.on(StageEvents.STAGE_PARTICIPANT_LEFT, handleParticipantLeave);
            stage.on(StageEvents.STAGE_PARTICIPANT_STREAMS_ADDED, handleMediaAdded);
            stage.on(StageEvents.STAGE_PARTICIPANT_STREAMS_REMOVED, handleMediaRemoved);
            stage.on(StageEvents.STAGE_STREAM_MUTE_CHANGED, handleParticipantMuteChange);

            stageRef.current = stage;

            await stageRef.current.join();

            return false;

        } catch (err) {
            console.error('Error joining stage', err);
            //alert(`Error joining stage: ${err.message}`);
            return true;
        }
    }

    function createParticipant(participantInfo) {
        return {
            ...participantInfo,
            streams: [],
        };
    }
    function isLocalParticipant(info) {
        return info.isLocal;
    }

    return (
        <div>
            {
                stageJoined &&
                <StageParticipants participants={participants} />
            }
        </div>
    )
}
