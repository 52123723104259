import { lotteryConstants } from '../_constants';

const windowGlobal = typeof window !== 'undefined' && window
let localStg
if (windowGlobal) localStg = JSON.parse(localStorage.getItem('lottery'));
const initialState = localStg ? { loading: false, currInfo: localStg.currInfo, resultHist: localStg.resultHist, streamToken: localStg.streamToken, isEndPage: localStg.isEndPage } : {
	loading: false, currInfo: {}, resultHist: [], streamToken: {}, isEndPage: false
};

export function lottery(state = initialState, action) {
	switch (action.type) {
		case lotteryConstants.CURRENT_INFO_SUCCESS:
			return {
				...state,
				loading: false,
				currInfo: action.currInfo
			};
		case lotteryConstants.CURRENT_INFO_FAILURE:
			return {
				...state,
				loading: false,
				currInfo: initialState.currInfo
			};
		case lotteryConstants.RESULT_HIST_SUCCESS:
			return {
				...state,
				loading: false,
				resultHist: action.resultHist.resultHist,
				isEndPage: action.resultHist.isEndPage
			};
		case lotteryConstants.RESULT_HIST_FAILURE:
			return {
				...state,
				loading: false,
				resultHist: initialState.resultHist
			};
		case lotteryConstants.STREAM_TOKEN_REQUEST:
			return {
				...state,
				loading: false,
				streamToken: ''
			};
		case lotteryConstants.STREAM_TOKEN_SUCCESS:
			return {
				...state,
				loading: false,
				streamToken: action.streamToken
			};
		case lotteryConstants.STREAM_TOKEN_FAILURE:
			return {
				...state,
				loading: false,
				streamToken: initialState.streamToken
			};
		default:
			return state
	}
}