import * as React from 'react';
import { Provider } from 'react-redux';
import { store } from './_helpers/';
import { Home } from './scenes/Home';
import { Header } from './components/Header';
import { Route, Switch } from 'react-router-dom';

import './custom.css'
import './tailwind.css'

export default () => (
    <Provider store={store}>
        <div className="">
            <Header />
            <Route exact path='/' component={Home} />
        </div>
    </Provider>
);
