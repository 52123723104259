////import config from 'react-global-configuration';

export const lotteryServices = {
    getCurrentInfo,
    getResultHist,
    generateStreamToken
};

function getCurrentInfo() {
    const _authHeader = {};
    const _reqInfo = {
        TrackId: 'ANNOUNCE1'
    }
    const requestOptions = {
        method: 'POST',
        headers: { _authHeader, 'Content-Type': 'application/json' },
        body: JSON.stringify(_reqInfo)
    };

    return fetch(`${process.env.REACT_APP_LOTTERY_API}/Lottery/GetAnnounceInfo`, requestOptions)
        .then(handleResponse, handleError)
        .then(result => {
            let _result = JSON.parse(localStorage.getItem('lottery'));
            if (_result) {
                _result.curInfo = result.data
            }
            else _result = { curInfo: result.data };
            localStorage.setItem('lottery', JSON.stringify(_result));
            return result.data;
        });
}

function getResultHist(reqInfo) {
    const _authHeader = {};
    const requestOptions = {
        method: 'GET',
        headers: { _authHeader, 'Content-Type': 'application/json' }
    };

    return fetch(`${process.env.REACT_APP_LOTTERY_API}/Lottery/GetLotteryPaging?page=${reqInfo.pageNum}&limit=${reqInfo.recordCount}`, requestOptions)
        .then(handleResponse, handleError)
        .then(result => {
            let _result = JSON.parse(localStorage.getItem('lottery'));

            var _isEndPage = false;
            if (result && result.data && result.data.data) {
                //console.log('result: ', result.data.data);
                if (result.data.data.length < reqInfo.recordCount) {
                    _isEndPage = true;
                }
            }

            if (_result) {
                _result.resultHist = result.data
                _result.isEndPage = _isEndPage;
            }
            else _result = { resultHist: result.data, isEndPage: _isEndPage };
            localStorage.setItem('lottery', JSON.stringify(_result));
            return _result;
        });
}

function generateStreamToken(reqInfo) {
    const _authHeader = {};
    const requestOptions = {
        method: 'GET',
        headers: { _authHeader, 'Content-Type': 'application/json' }
    };

    return fetch(`${process.env.REACT_APP_LOTTERY_API}/Lottery/GetToken/ANNOUNCE1`, requestOptions)
        .then(handleResponse, handleError)
        .then(result => {
            let _result = JSON.parse(localStorage.getItem('lottery'));
            if (_result) {
                _result.streamToken = result.data
            }
            else _result = { streamToken: result.data };
            localStorage.setItem('lottery', JSON.stringify(_result));
            return result.data;
        });
}

function handleResponse(response) {
    return new Promise((resolve, reject) => {
        if (response.ok) {
            // return json if it was returned in the response
            var contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
                response.json().then(json => {
                    if (json.statusCode == "200")
                        resolve(json)
                    else
                        reject(json)
                });
            } else {
                resolve();
            }
        } else {
            // return error message from response body
            response.text().then(text => reject(text));
        }
    });
}

function handleError(error) {
    return Promise.reject(error && error.message);
}