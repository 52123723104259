import React, { useRef, useEffect } from 'react';
import Video from './Video.js';
import Placeholder from './Placeholder.js'
import { StreamType } from 'amazon-ivs-web-broadcast';
import fsg from '../../_images/fsg.png'

export default function Participant({ id, userId, videoStopped, audioMuted, streams }) {
    //console.log('streams: ', streams)
    const videoStream = streams.find((stream) => stream.streamType === StreamType.VIDEO);
    const audioStream = streams.find((stream) => stream.streamType === StreamType.AUDIO);

    const audioRef = useRef(undefined);
    const streamVid = useRef();

    useEffect(() => {
        if (audioRef.current && audioStream) {
            audioRef.current.srcObject = new MediaStream([audioStream.mediaStreamTrack]);
        }
    }, [audioRef, audioStream]);

    function openFullscreen() {
        var elem = streamVid.current;
        //console.log('video: ', elem);
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
        }
    }

    return (
        <div className="column column-40" id="local-media" style={{ display: 'flex' }}>
            <div className="relative participantContainer">
                {videoStream && !videoStopped ? <Video stageStream={videoStream} videoRef={streamVid} /> : <Placeholder
                    userId={userId} />}
                <audio ref={audioRef} autoPlay />
                {audioMuted ? <span>Audio Muted!</span> : undefined}
            </div>
        </div>
    );
}


{
    //<a href="#" onClick={openFullscreen} className="absolute bottom-0 text-white">
    //    <img src={fsg} className="h-12" />
    //</a>
}