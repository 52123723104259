import { lotteryConstants } from '../_constants';
import { lotteryServices } from '../_services';

export const lotteryActions = {
    getCurrentInfo,
    getResultHist,
    generateStreamToken
};

function getCurrentInfo() {
    return async dispatch => {
        dispatch(request());
        lotteryServices.getCurrentInfo()
            .then(
                currInfo => {
                    dispatch(success(currInfo));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            )
            .catch((error) => {
                console.log('catch error with message: ' + error)
                dispatch(failure(error));
            });
    };

    function request() { return { type: lotteryConstants.CURRENT_INFO_REQUEST } }
    function success(currInfo) { return { type: lotteryConstants.CURRENT_INFO_SUCCESS, currInfo } }
    function failure(error) { return { type: lotteryConstants.CURRENT_INFO_FAILURE, error } }
}

function getResultHist(reqInfo) {
    return async dispatch => {
        dispatch(request());
        lotteryServices.getResultHist(reqInfo)
            .then(
                resultHist => {
                    dispatch(success(resultHist));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            )
            .catch((error) => {
                console.log('catch error with message: ' + error)
                dispatch(failure(error));
            });
    };

    function request() { return { type: lotteryConstants.RESULT_HIST_REQUEST } }
    function success(resultHist) { return { type: lotteryConstants.RESULT_HIST_SUCCESS, resultHist } }
    function failure(error) { return { type: lotteryConstants.RESULT_HIST_FAILURE, error } }
}

function generateStreamToken(reqInfo) {
    return async dispatch => {
        dispatch(request());
        lotteryServices.generateStreamToken(reqInfo)
            .then(
                streamToken => {
                    dispatch(success(streamToken));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            )
            .catch((error) => {
                console.log('catch error with message: ' + error)
                dispatch(failure(error));
            });
    };

    function request() { return { type: lotteryConstants.STREAM_TOKEN_REQUEST } }
    function success(streamToken) { return { type: lotteryConstants.STREAM_TOKEN_SUCCESS, streamToken } }
    function failure(error) { return { type: lotteryConstants.STREAM_TOKEN_FAILURE, error } }
}