import React, { useRef, useEffect } from 'react';

export default function Video({ stageStream, videoRef }) {
    //console.log('Stage Stream: ', stageStream);
    //const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current && stageStream) {
            //console.log('media stream: ', stageStream.mediaStreamTrack)
            videoRef.current.srcObject = new MediaStream([stageStream.mediaStreamTrack]);
        }
    }, [videoRef, stageStream]);

    return <video ref={videoRef} autoPlay muted playsInline className="rounded-md"/>;
}
