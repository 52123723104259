import React, { useContext } from 'react';
import Participant from './Participant.js';

export default function StageParticipants({ participants }) {
    return (
        <div>
            {[...participants.keys()].map((key) => {
                return <Participant key={key} {...participants.get(key)} />;
            })}
        </div>
    );
}
