import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { lotteryActions } from '../../_actions';
import ball0 from './images/0.png';
import ball1 from './images/1.png';
import ball2 from './images/2.png';
import ball3 from './images/3.png';
import ball4 from './images/4.png';
import ball5 from './images/5.png';
import ball6 from './images/6.png';
import ball7 from './images/7.png';
import ball8 from './images/8.png';
import ball9 from './images/9.png';

const HistTable = () => {
    const dispatch = useDispatch();
    const resultHist = useSelector(state => state.lottery.resultHist);
    const isEndPage = useSelector(state => state.lottery.isEndPage);
    const [currPage, setCurrPage] = useState(1);

    useEffect(
        () => {
            async function getResultHist() {
                var _reqInfo = {
                    pageNum: 1,
                    recordCount: 15
                }
                dispatch(lotteryActions.getResultHist(_reqInfo));
            }
            getResultHist();
        }, []
    )

    function getDayName(dateString) {
        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        var d = new Date(dateString);
        return days[d.getDay()];
    }

    function getFormattedDate(dateString) {
        var delimiter = '/';
        var d = new Date(dateString);
        //console.log('date: ', d);
        //console.log('month: ', d.getMonth());
        return (d.getMonth() + 1) + delimiter + d.getDate() + delimiter + d.getFullYear();
    }

    function getResultImg(resultNum) {
        switch (resultNum) {
            case '0':
                return (<img src={ball0} className="h-6 w-6 mr-1" />);
            case '1':
                return (<img src={ball1} className="h-6 w-6 mr-1" />);
            case '2':
                return (<img src={ball2} className="h-6 w-6 mr-1" />);
            case '3':
                return (<img src={ball3} className="h-6 w-6 mr-1" />);
            case '4':
                return (<img src={ball4} className="h-6 w-6 mr-1" />);
            case '5':
                return (<img src={ball5} className="h-6 w-6 mr-1" />);
            case '6':
                return (<img src={ball6} className="h-6 w-6 mr-1" />);
            case '7':
                return (<img src={ball7} className="h-6 w-6 mr-1" />);
            case '8':
                return (<img src={ball8} className="h-6 w-6 mr-1" />);
            case '9':
                return (<img src={ball9} className="h-6 w-6 mr-1" />);
        }
    }

    function getHist(e, pageInc) {
        e.preventDefault();
        if (currPage > 0) {
            const _nextPage = currPage + pageInc;
            setCurrPage(_nextPage);
            var _reqInfo = {
                pageNum: _nextPage,
                recordCount: 15
            }
            dispatch(lotteryActions.getResultHist(_reqInfo));
        }
    }

    return (
        <div className="flex flex-col ">
            <table className="divide-y divide-yellow-500 bg-gray-300 rounded-md">
                <thead>
                    <tr>
                        <th className="text-center py-2">
                            No
                        </th>
                        <th className="text-center py-2">
                            Period
                        </th>
                        <th className="text-left py-2">
                            Day
                        </th>
                        <th className="text-center py-2">
                            Date
                        </th>
                        <th className="text-center py-2">
                            Result
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        resultHist &&
                        resultHist.data &&
                        resultHist.data.map((result, index) =>
                            <tr key={index} className={` ${index % 2 === 0 ? 'bg-gray-200' : 'bg-gray-100'}`}>
                                <td className="py-1 text-center">
                                    {index + 1}
                                </td>
                                <td className="py-1 text-center">
                                    {result.periodNo}
                                </td>
                                <td className="py-1">
                                    {getDayName(result.period)}
                                </td>
                                <td className="py-1 text-center">
                                    {getFormattedDate(result.period)}
                                </td>
                                <td className="flex py-1 justify-center items-center">
                                    {
                                        //result.result &&
                                        //result.result.length === 4 &&
                                        //<div className="flex inline-flex">
                                        //    {getResultImg(result.result[0])}
                                        //    {getResultImg(result.result[1])}
                                        //    {getResultImg(result.result[2])}
                                        //    {getResultImg(result.result[3])}
                                        //</div>
                                    }
                                    {
                                        result.result &&
                                        result.result.length === 4 &&
                                        <div className="flex inline-flex">
                                            <span className="rounded-full bg-red-800 w-5 h-5 flex justify-center items-center text-white">{result.result[0]}</span>
                                            <span className="rounded-full bg-red-800 w-5 h-5 flex justify-center items-center text-white ml-1">{result.result[1]}</span>
                                            <span className="rounded-full bg-red-800 w-5 h-5 flex justify-center items-center text-white ml-1">{result.result[2]}</span>
                                            <span className="rounded-full bg-red-800 w-5 h-5 flex justify-center items-center text-white ml-1">{result.result[3]}</span>
                                        </div>
                                    }
                                </td>
                            </tr>
                        )}
                </tbody>
            </table>
            <div className="flex mt-2">
                <a href="#" onClick={(e) => { currPage > 1 ? getHist(e, -1) : e.preventDefault() }} className={`${currPage > 1 ? '' : 'bg-gray-200'} w-24 px-4 py-2 border text-xs sm:text-sm text-center rounded-md`}>Newer</a>
                <a href="#" onClick={(e) => { isEndPage ? e.preventDefault() : getHist(e, 1) }} className={`${!isEndPage ? '' : 'bg-gray-200'} ml-4 w-24 px-4 py-2 border text-xs sm:text-sm text-center rounded-md`}>Older</a>
            </div>
        </div>
    );
}

export default HistTable;