export const lotteryConstants = {
    CURRENT_INFO_REQUEST: 'CURRENT_INFO_REQUEST',
    CURRENT_INFO_SUCCESS: 'CURRENT_INFO_SUCCESS',
    CURRENT_INFO_FAILURE: 'CURRENT_INFO_FAILURE',
    
    RESULT_HIST_REQUEST: 'RESULT_HIST_REQUEST',
    RESULT_HIST_SUCCESS: 'RESULT_HIST_SUCCESS',
    RESULT_HIST_FAILURE: 'RESULT_HIST_FAILURE',

    STREAM_TOKEN_REQUEST: 'STREAM_TOKEN_REQUEST',
    STREAM_TOKEN_SUCCESS: 'STREAM_TOKEN_SUCCESS',
    STREAM_TOKEN_FAILURE: 'STREAM_TOKEN_FAILURE'
};