import React, { useState, useEffect } from 'react';
import logo from '../../_images/logo.png';
import { isMobile } from 'react-device-detect';

const Header = () => {

    return (
        <div className={`flex flex-row px-4 py-4 sm:py-4 text-sm border-b border-gray-300 font-bold ${isMobile ? 'justify-center' : ''}`}>
            <div className="">
                MARBLE RACE 4D LOTTERY
            </div>
        </div>
    );
}

export default Header;


{
    //<div className="flex flex-1 flex-row-reverse">}
    //    <div>
    //    </div>
    //</div>
}